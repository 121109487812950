<template>
    <div class="">
        <div style="width:100%;display: flex;justify-content: flex-end;align-items: center">
            <button class="badge badge-success" style="padding: 5px 15px;margin-right: 5px" @click="add2customercenter"
                    v-if="position">
                선택문의
            </button>
            <button class="badge badge-success" style="padding: 5px 15px;margin-right: 5px;background-color: #ff7f00"
                    @click="deleteBet" v-if="position">
                선택삭제
            </button>
            <button class="badge badge-red" style="padding: 5px 15px;margin-right: 5px" @click="deleteAllLeisureBetInfo"
                    v-if="position">
                전체삭제
            </button>
        </div>
        <table class="utable" v-for="(item,idx) in betList">
            <tr>
                <th style="width: 33%">종목</th>
                <th style="width: 33%">회차</th>
                <th>배팅시간</th>
            </tr>
            <tr>
                <td style="color: yellow">{{item.kindConfig.kindName}}</td>
                <td>{{item.leisureGame.sequence}}</td>
                <td>{{item.createTime|datef('MM-DD HH:mm')}}</td>
            </tr>
            <tr>
                <th>배당</th>
                <th>배팅금</th>
                <th>예상적중금</th>
            </tr>
            <tr>
                <td  style="color: yellow">{{item.betOdds}}</td>
                <td class="text-white">{{item.betCash|comma}}원</td>
                <td class="text-white">{{item.totalCash|comma}}원</td>
            </tr>
            <tr class="">
                <th>내역</th>
                <td colspan="2" style="color: #ffffff">
                    <p>{{item.attributeConfig.attrName}}
                        <span v-if="item.leisureGame.homeTeamName != null"> ({{item.leisureGame.homeTeamName + ' vs '+item.leisureGame.awayTeamName}})</span>
                    </p>
                    <p style="font-weight: normal"
                       v-if="item.attribute === leisureConst.LEISURE_ATTRIBUTE_LOTTO_POWERBALL">
                        {{item.selectedValue}}</p>
                    <p style="font-weight: normal;color: #ffa500"
                       v-if="item.attribute !== leisureConst.LEISURE_ATTRIBUTE_LOTTO_POWERBALL">
                        <font :class="{'bet365color':item.isbet365betting}">{{item.selectedValueText}}</font>

                    </p>
                </td>
            </tr>
            <tr class="">
                <th>
                    <input v-if="position" type="checkbox"
                           @click="checkBetId(item.id)">
                    결과
                </th>
                <td style="font-weight: bold;font-size: 14px;">
                    <span v-if="item.betResult === sportsConst.BET_RESULT_WIN" class="text-skyblue">당첨</span>
                    <span v-if="item.betResult === sportsConst.BET_RESULT_LOSE" class="text-red">낙첨</span>
                    <span v-if="item.betResult === sportsConst.BET_RESULT_SPECIALCASE" class="text-orange">적특</span>
                    <span v-if="item.betResult === sportsConst.BET_RESULT_CANCEL" class="text-red">취소</span>
                    <span v-if="item.betResult === sportsConst.BET_RESULT_WAITING" class="text-white">대기중</span>
                </td>
                <td class="btns">
                    <!--<button class="btn01 text-red" v-if="item.betResult === sportsConst.BET_RESULT_WAITING && position"
                            @click="cancelBet(item.id)"> <i class="fa fa-close"> 취소</i>
                    </button>-->
<!--                    <button class="badge badge-red" v-if="item.betResult !== sportsConst.BET_RESULT_WAITING && position"-->
<!--                            @click="deleteBet(item.id)"><i class="fa fa-close"> 삭제</i>-->
<!--                    </button>-->
                    <!--<router-link href="javascript:void(0)" tag="a"  v-if="position"
                                 :to="{path: '/customer_center', query: {lbid:item.id,t: new Date().getTime()}}"
                                 class="badge badge-success ml-2">  <i class="fa fa-question-circle-o"> 문의하기</i></router-link>-->
                </td>
            </tr>
        </table>

    </div>
</template>

<script>
    import sportsConst from "../../common/sportsConst";
    import leisureConst from "../../common/leisureConst";
    import {deleteAllLeisureBet, deleteByIds} from "../../network/leisureRequest";

    export default {
        name: "LeisureBetListComp",
        props: {
            betList: {
                type: Array,
                default() {
                    return []
                }
            },
            position: {
                type: Number,
                default: 1 // 0:게시글에등록된 배팅내역(삭제,취소,선택체크 를 숨김) 1: 배팅내역 리스트
            }
        },
        data() {
            return {
                sportsConst,
                leisureConst,
                betIds: [],
            }
        },
        methods: {
            askandaddbetids() {
                this.$swal({
                    title: '',
                    type: 'question',
                    confirmButtonText: ' 예 ',
                    cancelButtonText: '아니오'
                }).then((res) => {
                    if (res.value) {
                        deleteAllLeisureBet().then(res => {
                            if (res.data.success) {
                                this.$emit('cancelOrDelBet')
                            } else {
                                this.$swal({
                                    title: res.data.msg,
                                    type: 'error',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            }
                        })
                    }
                })
            },
            deleteAllLeisureBetInfo() {
                this.$swal({
                    title: '전체 배팅내역을 삭제하시겠습니까?',
                    type: 'question',
                    confirmButtonText: ' 예 ',
                    cancelButtonText: '아니오'
                }).then((res) => {
                    if (res.value) {
                        deleteAllLeisureBet().then(res => {
                            if (res.data.success) {
                                this.$emit('cancelOrDelBet')
                            } else {
                                this.$swal({
                                    title: res.data.msg,
                                    type: 'error',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            }
                        })
                    }
                })
            },
            checkBetId(id) {
                let index = this.betIds.findIndex((item) => {
                    return item === id
                });
                if (index === -1) {
                    this.betIds.push(id)
                } else {
                    this.betIds.splice(index, 1)
                }
                this.$emit('betInfoChecked', this.betIds)
            },
            deleteBet() {
                if (this.betIds.length == 0) {
                    this.$swal({
                        title: '배팅내역을 선택해주세요',
                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }
                this.$swal({
                    title: '선택하신 배팅내역을 삭제하시겠습니까?',
                    type: 'question',
                    confirmButtonText: ' 예 ',
                    cancelButtonText: '아니오'
                }).then((res) => {
                    this.betInfo = {selectedVals: []}
                    if (res.value) {
                        let ids = this.betIds.join(',');
                        deleteByIds(ids).then(res=>{
                            this.$emit('cancelOrDelBet')
                        })
                    }
                })
            },
            cancelBet(id) {
                this.$swal({
                    title: '해당 배팅내역을 취소하시겠습니까?',
                    type: 'question',
                    confirmButtonText: ' 예 ',
                    cancelButtonText: '아니오'
                }).then((res) => {
                    this.betInfo = {selectedVals: []}
                    if (res.value) {
                        this.$emit('cancelBet', id)
                    }
                })
            },
            add2customercenter() {
                //:to=""
                if (this.betIds.length == 0) {
                    this.$swal({
                        title: '배팅내역을 선택해주세요',
                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }
                this.$router.push({
                    path: '/customer_center',
                    query: {lbid: this.betIds.join(","), t: new Date().getTime()}
                })
            },
        }
    }
</script>

<style scoped>
    @import url("../../assets/css/base.css");
    @import url("../../assets/css/common.css");
    @import url("../../assets/css/style.css");
    @import url("../../assets/css/media.css");
    @import url("../../assets/css/sports.css");

    table {
        border: 1px solid #5e5e5e;
        border-bottom: 2px solid #d4d4d4;
        border-radius: 0px;
        margin: 10px auto;
        font-size: 12px;
    }

    table th {
        color: #fff9ff;
        height: 30px;
        line-height: 30px;

    }

    table td {
        height: 30px;
        line-height: 30px;
    }
    .bet365color{
        color: #00ff00;
    }

</style>